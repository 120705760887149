import React from 'react'
import { Link } from 'gatsby'

const SingleTagTemplate = ({data, pageContext}) => {
  const { posts, tagName } = pageContext
  console.log(posts)
  return (
    <div>
      <h1>{`Posts of ${tagName}`}</h1>
      <ul>
        {posts.map(({frontmatter: {title, path}}) => (
          <li key={path}>
            <Link to={path} style={{display: 'block'}}>
              {title}
            </Link>
          </li>
        ))}

      </ul>
    </div>
  )
}

export default SingleTagTemplate